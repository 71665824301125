import React from "react";
import dayjs from "dayjs";
import KrgDataTable from "@kargo/shared-components.krg-data-table";
import KrgChip from "@kargo/shared-components.krg-chip";
import { makeStyles } from "@mui/styles";
import KrgCheckbox from "@kargo/shared-components.krg-checkbox";
import { Video } from "../../api/types";
import VideoModal from "../Modals/VideoModal";

const useStyles = makeStyles({
  table: {
    "& thead th": {
      background: "transparent",
      color: "black",
      fontSize: "11px",
      fontWeight: 600,
    },
    "& tbody": {
      borderWidth: "2px 0",
      borderColor: "black",
      borderStyle: "solid",
      "& tr:hover": {
        background: "#EEF3F7",
      },
      "& td": {
        border: 0,
      },
    },
  },
});

export default function VideosTable({
  videos,
  selected,
  onVideoToggle,
  onToggleAll,
}: {
  videos: Video[];
  selected?: string[];
  onVideoToggle: (video: string, isSelected: boolean) => any;
  onToggleAll: (checked: boolean) => any;
}) {
  const classes = useStyles();
  const isAllChecked = videos.every((v) => selected.includes(v.token));
  const [modalVideoIndex, setModalVideoIndex] = React.useState(-1);
  const modalVideo = videos[modalVideoIndex];
  const totalVideos = videos.length;
  return (
    <>
      <VideoModal
        video={modalVideo?.token}
        isOpen={modalVideoIndex > -1}
        onClose={() => setModalVideoIndex(-1)}
        onNavigatePrev={() =>
          setModalVideoIndex((modalVideoIndex - 1 + totalVideos) % totalVideos)
        }
        onNavigateNext={() =>
          setModalVideoIndex((modalVideoIndex + 1) % totalVideos)
        }
      />
      <KrgDataTable
        theme={KrgDataTable.THEME_ENUM.v2}
        className={classes.table}
        config={{
          pagination: {
            initialPageSize: 100,
          },
          data: {
            columns: [
              {
                type: KrgDataTable.CELL_TYPE_ENUM.checkbox,
                headerName: "",
                field: "isChecked",
                renderHeader: () => (
                  <KrgCheckbox
                    theme={KrgCheckbox.THEME_ENUM.v2}
                    isChecked={isAllChecked}
                    onToggle={onToggleAll}
                  />
                ),
                renderCell: (r) => (
                  <KrgCheckbox
                    theme={KrgCheckbox.THEME_ENUM.v2}
                    isChecked={r.isChecked}
                    onToggle={(isChecked) => onVideoToggle(r.token, isChecked)}
                  />
                ),
              },
              {
                headerName: "Video Title",
                type: KrgDataTable.CELL_TYPE_ENUM.subtitle,
                field: "title_with_token_as_subtitle",
                onClick: (_, r) => setModalVideoIndex(r.index),
              },
              {
                headerName: "Category",
                field: "category",
                width: "100px",
              },
              {
                headerName: "Tags",
                field: "tags",
                renderCell: (r) => (
                  <>
                    {r.tags?.map((t) => (
                      <KrgChip
                        style={{ margin: "2px" }}
                        theme={KrgChip.THEME_ENUM.v2}
                        type={KrgChip.TYPE_ENUM.transparent}
                        data={{ text: t, id: t }}
                      />
                    ))}
                  </>
                ),
              },
              {
                headerName: "Publish Date",
                field: "publish_date",
                width: "120px",
              },
              {
                headerName: "Duration",
                field: "duration",
                width: "110px",
              },
              {
                headerName: "State",
                field: "state",
                width: "100px",
              },
            ],
            rows: videos.map((v, i) => ({
              ...v,
              title_with_token_as_subtitle: {
                title: v.title,
                subtitle: v.token,
              },
              publish_date: dayjs(v.created_at).format("MM-DD-YY"),
              duration: dayjs
                .duration(
                  v.local?.duration ||
                    v.media_info?.duration ||
                    v.default_stream?.media_info?.duration,
                  "seconds",
                )
                .format((() => "HH:mm:ss")()),
              original_file_name: v.media_info.original_file_name,
              isChecked: selected?.includes(v.token),
              index: i,
            })),
          },
        }}
      />
    </>
  );
}
