import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import LoadingCircle from "../../../components/LoadingCircle";
import Table from "../../../components/Table";
import {
  useSuperAdminContext,
} from "../../../routes/super_admin";

import "./styles.scss";
import { useDomainConfig } from "../../../hooks/useDomainConfig";
import { useDomain } from "../../../hooks/useDomain";
import { ISuperAdminRouteParams } from "../../../routes/superadmin_types";

export default function RevisionPage() {
  const { addPathToBreadCrumb } = useSuperAdminContext();
  const history = useHistory();
  const params = useParams<ISuperAdminRouteParams>();
  const [revisions, setRevisions] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { getRevisions } = useDomainConfig(
    params.playerToken,
    params.domainToken || "",
  );
  const { data } = useDomain(params.domainToken || "");

  React.useEffect(() => {
    if (data?.domain) {
      addPathToBreadCrumb([
        {
          path: `/admin/domain/${params.domainToken}`,
          pathName: data?.domain,
        },
        {
          path: `/admin/domain/${params.domainToken}/${params.playerToken}`,
          pathName: "No revision yet",
        },
      ]);
    }
  }, [data]);

  React.useEffect(() => {
    if (data) {
      const latestConfig = data.configs
        .filter((c) => c.token === params.playerToken)
        .shift();
      getRevisions(params.playerToken, {
        onSuccess: (res: any[]) => {
          res.push({...latestConfig, isLatest: true});
          setRevisions(
            res.sort((a, b) => Number(a.revision) - Number(b.revision)),
          );
        },
        onError: () => {
          setRevisions([{...latestConfig, isLatest: true}]);
        },
        onSettled: () => {
          addPathToBreadCrumb([
            {
              path: `/admin/domain/${params.domainToken}`,
              pathName: data?.domain,
            },
            {
              path: `/admin/domain/${params.domainToken}/${params.playerToken}`,
              pathName: latestConfig.key,
            },
          ]);
          setIsLoading(false);
        },
      });
    }
  }, [params.playerToken, data]);

  if (isLoading) {
    return (
      <div className="krg-loading-wrapper">
        <LoadingCircle />
      </div>
    );
  }

  const columns = [
    {
      field: "revision",
      headerName: "Revision number",
      onClick: (e, row) => {
        if (row.isLatest) {
          history.push(
            `/admin/domain/${params.domainToken}/${params.playerToken}/edit`,
          );
          return;
        }
        history.push(
          `/admin/domain/${params.domainToken}/${params.playerToken}/${row.revision}/edit`,
        );
      },
      renderCell: (row) => <p>{row.revision}</p>,
    },
    {
      field: "notes",
      headerName: "Notes",
      renderCell: (row) => (row.notes ? <p>{row.notes}</p> : null),
    },
    {
      field: "updated_at",
      headerName: "Last update",
      renderCell: (row) => (
        <p>
          {dayjs(row.updated_at).format("DD/MMM/YY ")}
          {dayjs(row.updated_at).format(" HH:mm:ss")}
        </p>
      ),
    },
    {
      field: "created_by_user",
      headerName: "Updated by",
      renderCell: (row) => <p>{row.created_by_user}</p>,
    },
    {
      field: "",
      headerName: "Edit",
      onClick: (_, row) => {
        if (row.isLatest) {
          history.push(
            `/admin/domain/${params.domainToken}/${params.playerToken}/edit`,
          );
          return;
        }
        history.push(
          `/admin/domain/${params.domainToken}/${row.token}/${row.revision}/edit`,
          {
            config: row,
          },
        );
      },
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      renderCell: (row) => <EditIcon color="primary" />,
    },
  ];
  const rows = revisions?.map((d) => d) || [];

  return (
    <Box padding="20px">
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Table columns={columns} rows={rows} size={14} />
      </Box>
    </Box>
  );
}
