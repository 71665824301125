/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Box, Grid } from "@mui/material";
import KrgSnackbar from "@kargo/shared-components.krg-snackbar";
import { debounce } from "lodash";
import useVideos from "../../hooks/useVideos";
import SearchInput from "../../components/SearchInput";
import VideosAccordion from "./VideosAccordion";
import useMRSSFeeds from "../../hooks/useMRSSFeeds";
import FeedAccordion from "./FeedAccordion";
import CategoriesSelect from "../../components/CategoriesSelect/CategoriesSelect";
import DurationSelect from "../../components/DurationSelect/DurationSelect";
import { DatePicker, DateRange } from "../../components/DatePicker/DatePicker";
import LoadingCircle from "../../components/LoadingCircle";
import tracker from "../../helpers/tracker";
import Snackbar from "../../components/Snackbar";
import { VideoCard } from "../../components/VideoCard";

type Props = {
  asAdmin?: boolean;
  publisherSlug?: string;
  createOptions?: {
    isVisible: boolean;
    onClose: () => void;
    onSubmit: () => void;
  };
  selected: string[];
  onChangeSelected: (selected: string[]) => void;
  uploadPreviewVideoToken?: { token: string };
};
export default function MyLibrary({
  asAdmin,
  publisherSlug,
  selected,
  onChangeSelected,
  createOptions: copt,
  uploadPreviewVideoToken,
}: Props) {
  const [showPlaylist, setShowPlaylist] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(true);
  const [search, setSearch] = React.useState("");
  const { data: feeds, query: feedsQuery } = useMRSSFeeds({
    ...(publisherSlug && { slug: publisherSlug }),
  });
  const [selectedCategories, setSelectedCategories] = React.useState<string[]>(
    [],
  );
  const [selectedDurations, setSelectedDurations] = React.useState<number[]>(
    [],
  );
  const [dateRange, setDateRange] = React.useState<DateRange | undefined>();
  const query = {
    ...(search && { text: search }),
    ...(selectedCategories.length > 0 && { category: selectedCategories }),
    ...(selectedDurations.length > 0 && { duration: selectedDurations }),
    ...(dateRange &&
      dateRange.to &&
      dateRange.from && {
        date_range: dateRange,
      }) /* We can remove the restriction if needed */,
    ...(publisherSlug && { slug: publisherSlug }),
  };
  const { videos, query: videosQuery } = useVideos(query);
  const debouncedTrack = React.useMemo(
    () =>
      debounce((event, data) => {
        tracker.track(event, data);
      }, 3000),
    [],
  );
  React.useEffect(() => {
    setShowPlaylist((asAdmin && Boolean(publisherSlug)) || !asAdmin);
  }, [asAdmin, publisherSlug]);

  React.useEffect(() => {
    if (
      search ||
      selectedCategories?.length > 0 ||
      selectedDurations?.length > 0 ||
      dateRange
    ) {
      debouncedTrack("Search", {
        resource: "video",
        text: search,
        categories: selectedCategories,
        durations: selectedDurations,
        date: dateRange,
      });
    }
  }, [search, selectedCategories, selectedDurations, dateRange]);

  return (
    <Box>
      <Grid container>
        <Grid container item xs={12} md={6} justifyContent="flex-start">
          <SearchInput
            placeholder="Video Title, Date"
            onDebounceEnd={setSearch}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          spacing={2}
          justifyContent="flex-end"
        >
          <Grid item xs={12} sm={4}>
            <CategoriesSelect
              asAdmin={asAdmin}
              value={selectedCategories}
              onChange={(cat: string) => {
                setSelectedCategories(cat);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DurationSelect
              asAdmin={asAdmin}
              value={selectedDurations}
              onChange={(dur: number) => {
                setSelectedDurations(dur);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DatePicker
              onDateRangeChange={(selectedDate) => {
                setDateRange(selectedDate);
              }}
              value={dateRange}
            />
          </Grid>
        </Grid>
      </Grid>
      {videosQuery.isFetched && feedsQuery.isFetched ? (
        <>
          {uploadPreviewVideoToken.token && (
            <Box style={{ padding: "10px 20px 20px 20px" }}>
              <Box display="flex">
                <VideoCard video={uploadPreviewVideoToken} />
              </Box>
            </Box>
          )}
          {showPlaylist && (
            <>
              {feeds &&
                feeds.length > 0 &&
                feeds.map((f) => (
                  <FeedAccordion
                    key={f.token}
                    feed={f}
                    query={query}
                    selected={selected}
                    onChangeSelected={onChangeSelected}
                  />
                ))}
              <VideosAccordion
                title="Uploaded Videos"
                query={{ upload_type: "cms,api", ...query }}
                selected={selected}
                onChangeSelected={onChangeSelected}
              />
            </>
          )}
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 259px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingCircle />
        </div>
      )}
      {asAdmin && !showPlaylist && (
        <Snackbar
          autoHideDuration={6000}
          type={KrgSnackbar.TYPE_ENUM.warning}
          isOpen={isOpen}
          theme={KrgSnackbar.THEME_ENUM.v2}
          button={{
            text: "continue",
            onClick: () => {
              setIsOpen(false);
              console.warn(
                "Please switch to a publisher account at the top right-hand \n " +
                  "corner to view uploaded videos, MRSS feeds and playlists",
              );
            },
          }}
          text={
            <div style={{ fontSize: "13px" }}>
              <p>Please switch to a publisher account at the top right-hand</p>
              <p>corner to view uploaded videos, MRSS feeds and playlists</p>
            </div>
          }
        />
      )}
    </Box>
  );
}
