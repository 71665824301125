import { Config } from "../api/types";

export function getDisplayTitle(video: {
  title?: string,
  media_info: {
    original_file_name?: string
  }
}) {
  return video && (video.title || video.media_info.original_file_name);
}

export function getRangeSelection(
  video: string,
  videos: string[],
  isShiftKeyOn: boolean,
  prevSelected?: string,
) {
  const prevVideo = isShiftKeyOn ? prevSelected || video : video;
  const prevIndex = videos.indexOf(prevVideo);
  const currIndex = videos.indexOf(video);
  const [start, end] = [prevIndex, currIndex].sort((a, b) => a - b);
  return videos.filter(
    (item: any, idx: number) => idx >= start && idx <= end,
  ).map((i) => i);
}

export const getPlayerNameByConfig = (config: Config) => {
  if (config?.key) return config.key;
  if (config?.type) {
    if (config?.type === 'prod' || config?.type === 'socan') return 'Production Player';
    if (config?.type === 'stage' || config?.type === 'socan-stage' || config?.type === 'socan-staging') return 'Staging Player';
  }
  return 'Related Content Player';
};
