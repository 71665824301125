import { useCallback } from "react";
import {
  useMutation,
  useInfiniteQuery,
  useQueryClient,
  InfiniteData,
} from "react-query";
import useAPI from "../api/useAPI";
import { VideoInput } from "../api/types";

type Query = {
  category?: string | string[];
  page?: number;
  limit?: number;
  skip?: number;
  text?: string;
  tags?: string[];
  group?: string;
  imported_from?: string;
  date_range?: Record<string, string>;
  duration?: number[] | string;
  [key: string]: any;
};

const LIMIT = 50;

function getQueryParams({
  page = 0,
  limit = LIMIT,
  skip,
  text,
  category,
  group,
  imported_from,
  duration,
  date_range,
  ...params
}: Query) {
  const query: Query = {
    ...(imported_from && { imported_from }),
    ...(group && { group }),
    skip: skip || limit * page,
    limit,
    ...(category && { category }),
    sort: JSON.stringify({ created_at: "desc" }),
    ...(text && { search_text: text }),
    ...params,
  };
  if (duration && typeof duration !== "string") {
    const durationObject: any = {};
    if (duration.includes(-1)) durationObject.min = 601;
    const maxValue = Math.max(...duration);
    if (maxValue && maxValue !== -1) durationObject.max = maxValue;
    query.duration = Object.keys(durationObject).length !== 0 && durationObject;
  }
  if (date_range) {
    const dateRangeObj: any = {};
    if (date_range.to) {
      const to = new Date(date_range.to);
      to.setDate(to.getDate() + 1);
      dateRangeObj.end = to.getTime();
    }
    if (date_range.from) {
      dateRangeObj.start = Date.parse(date_range.from);
    }
    if (Object.keys(dateRangeObj).length) query.created_range = dateRangeObj;
  }
  return query;
}

export default function useVideos(query: Query) {
  const queryClient = useQueryClient();
  const { api, didLoad } = useAPI();
  const fetchVideos = useCallback(
    (params) => api.Media.index(getQueryParams(params)),
    [api],
  );
  const videosQuery = useInfiniteQuery({
    queryKey: ["videos", query],
    queryFn: ({ pageParam = 0 }) => fetchVideos({ page: pageParam, ...query }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.medias?.length < (query.limit || LIMIT)) return undefined;
      return pages.length;
    },
    enabled: didLoad,
    onSuccess: (data) => {
      data.pages
        .reduce((videos, page) => videos.concat(page.medias), [])
        .forEach((video) =>
          queryClient.setQueryData(["video", video.token], video),
        );
    },
    staleTime: Infinity,
  });
  const createMutation = useMutation({
    mutationFn: ({
      data,
      file,
      image,
      publisherUsername,
      onCreate,
    }: {
      data?: any;
      file: File;
      image?: File;
      publisherUsername?: string;
      onCreate?: (data: VideoInput & { token: string }) => any;
    }) =>
      api.createVideo({
        data: {
          ...data,
          media_info: {
            original_file_name: file.name,
            original_extension: file.name.split(".").pop(),
            ...data?.media_info,
          },
          media_type: "video",
          provider: "cms",
        },
        file,
        image,
        publisherUsername,
        onCreate: (d) => {
          let videoData;
          queryClient.setQueryData<Record<string, any>>(
            ["video", d.token],
            (oldData) => {
              videoData = { ...oldData, ...d };
              if (!oldData) {
                queryClient.setQueryData<InfiniteData<{ medias: any[] }>>(
                  ["videos", {}],
                  (videos) => {
                    if (videos) videos.pages[0].medias.unshift(videoData);
                    return videos;
                  },
                );
              }
              return videoData;
            },
          );
          if (file) {
            const video = document.createElement("video");
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            video.src = URL.createObjectURL(file);
            video.currentTime = 0.1;

            video.addEventListener("loadeddata", () => {
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;

              ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

              const imgURL = canvas.toDataURL("image/png");
              queryClient.setQueryData<Record<string, any>>(
                ["video", d.token],
                (oldData) => ({
                  ...oldData,
                  local: {
                    image_url: imgURL,
                    duration: video.duration,
                  },
                }),
              );
            });
          }
          if (onCreate) onCreate(d);
        },
        onProgress: (token, e) => {
          const percentage = (e.loaded / e.total) * 100;
          queryClient.setQueryData<Record<string, any>>(
            ["video", token],
            (oldData) => ({
              ...oldData,
              ...(percentage < 100 && { state: "uploading" }),
              percentage_uploaded: percentage,
            }),
          );
        },
      }),
    onSuccess: (data) => {
      queryClient.setQueryData<Record<string, any>>(
        ["video", data.token],
        (oldData) => ({ ...oldData, ...data }),
      );
      queryClient.invalidateQueries({ queryKey: ["videos"] });
    },
  });
  const videos =
    videosQuery.data?.pages.reduce((arr, p) => {
      p.medias.forEach((newVideo) => {
        if (arr.find((video) => video.token === newVideo.token)) return;
        arr.push(newVideo);
      });
      return arr;
    }, []) || [];
  return {
    query: videosQuery,
    videos,
    api: {
      create: createMutation.mutate,
    },
    mutations: {
      createMutation,
    },
  };
}
