/* eslint-disable react/function-component-definition, react/prop-types */
import React from "react";
import { Box, Button, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useHistory } from "react-router-dom";
import useSnackbar from "../../hooks/useSnackbar";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const { snackbarError } = useSnackbar();
  const history = useHistory();
  React.useEffect(() => {
    snackbarError(error.message);
    const timeout = setTimeout(() => {
      history.push("/publisher-hub");
      resetErrorBoundary();
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [history, error.message]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f8d7da",
        color: "#721c24",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
      }}
    >
      <ErrorOutlineIcon sx={{ fontSize: "4rem", marginBottom: "16px" }} />
      <Typography variant="h4" component="h1" gutterBottom>
        Something went wrong
      </Typography>
      <Typography variant="body1" gutterBottom>
        {error.message}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={resetErrorBoundary}
        sx={{ marginTop: "16px" }}
      >
        Try again
      </Button>
    </Box>
  );
};

export default ErrorFallback;
