import React from 'react';
import { MULTIPLE_TYPE_ENUM } from '@kargo/shared-components.krg-select/shared/enums';
import useCategories from '../../hooks/useCategories';
import Select from '../Select';

type Props = {
  isEnabled?: boolean,
  value?: string[],
  onChange?: (cat: string) => any
};
export default function CategoriesSelect({
  isEnabled = true, value, onChange,
}: Props) {
  const { categories } = useCategories();
  categories.sort();
  return (
    <Select
      label="Video Category"
      isEnabled={isEnabled && categories.length > 0}
      value={value}
      items={categories.map((c) => ({
        text: c, /* Maybe we need to parse the categories to make them look better */
        value: c,
      }))}
      isMultiple
      multipleType={MULTIPLE_TYPE_ENUM.checkmark}
      onChange={onChange}
    />
  );
}
