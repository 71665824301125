export type Config = {
  config: Record<string, any>;
  created_at: string;
  created_by: string;
  token: string;
  type?: string;
  key?: string;
  notes?: string;
  updated_at: string;
  updated_by: string;
  is_config_deletable?: boolean;
};

export type EditableConfig = Pick<Config, "config" | "type" | "key">;

export type MRSSFeed = {
  active: boolean;
  created_at: string;
  created_by: string;
  feed_kind: string;
  feed_type: string;
  feed_url: string;
  healthy: boolean;
  id: string;
  next_run: string;
  repeat_days: number;
  running: boolean;
  scheme: {
    video_description: string;
    video_duration: string;
    video_guid: string;
    video_language: string;
    video_publication_date: string;
    video_thumbnail: string;
    video_title: string;
    video_type: string;
    video_url: string;
    videos: string;
  };
  token: string;
  updated_at: string;
  user: string;
  name?: string;
  __v: string;
  _id: string;
};

export type Stream = {
  media_info: {
    duration: number;
    size: number;
    width: number;
    height: number;
  };
  image_info: {
    size: number;
    width: number;
    height: number;
  };
  file_path: string;
  _id: string;
  token: string;
  image_path: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  id: string;
  url: string;
  image_url: string;
};

export type VideoInput = {
  group?: string;
  media_info: {
    original_file_name: string;
    original_extension: string;
  };
  media_type: string;
};

export type Video = {
  token: string;
  media_info: {
    duration: number;
    original_file_name: string;
    original_extension: string;
    size: number;
    thumbnail_width: number;
    thumbnail_height: number;
  };
  audio_transcription: {
    words: string[];
    scores: number[];
    times: Array<{
      start: number;
      end: number;
    }>;
    text: string;
    topics: string[];
    keywords: string[];
  };
  _id: string;
  state: string;
  transcoding_state: string;
  transcription_state: string;
  media_type: string;
  key?: string;
  title?: string;
  description?: string;
  group: string;
  category: string;
  language: string;
  tags: string[];
  provider: string;
  provider_data: Record<string, any>;
  original_stream: Stream;
  transcoded_streams: Stream[];
  media_jobs: Array<{
    state: string;
    external_id: string;
    _id: string;
    created_at: string;
    updated_at: string;
    created_by: string;
  }>;
  created_at: string;
  updated_at: string;
  created_by: string;
  __v: number;
  updated_by: string;
  assets_path: string;
  default_stream: Stream;
  hls_stream: Stream;
  path: string;
  id: string;
};

export type Domain = {
  configs: Config[];
  created_at: string;
  created_by: string;
  domain: string;
  token: string;
  updated_at: string;
  updated_by: string;
};

export type PlaylistInput = {
  title?: string;
  key?: string;
  medias?: string[];
  username?: string;
  shuffle?: boolean;
};

export type Playlist = PlaylistInput & {
  token: string;
  medias: string[];
};

export type LookerObjects = {
  dashboard_id: number;
  [key: string]: unknown;
};

export type UserInput = {
  username: string;
  name?: string;
  domains?: string[];
  groups?: string[];
  ad_units?: string[];
  playlists?: string[];
  publisher?: Record<string, UserPermissionPick[]>;
};

export type User = UserInput & {
  email?: string;
  user_name?: string;
  role?: string;
};

export type PublisherQuery = {
  slug?: string;
};

export type CreatePublisherInput = {
  name: string;
  slug: string;
  group?: string;
  domains?: string | [];
  create_user?: boolean;
  username?: string;
};

export type CreatedPublisherResponseData = {
  _id: string;
  name: string;
  slug: string;
  group: string;
  domains: [];
  playlists: [];
  users: [];
  created_at: string;
  updated_at: string;
  created_by: string;
};

export interface IUserSession {
  username: string;
  attributes: Record<string, unknown>;
  groups?: string[];
  role?: string;
}

export interface DomainResponse {
  domain: Domain;
}
export interface IApiError {
  message: string;
}

export enum EApiStatusError {
  CONFLITED = 409,
  NOT_FOUND = 404,
  INTERNAL_ERROR = 500,
  DUPLICATED = 412,
}
export type BulkDomainsProperties = {
  publishers: string[];
  changes: any;
  notes: string;
};
export type UserPermissionPick = Pick<IUserSession, "role" | "username">;
