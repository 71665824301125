import React, { useEffect, useState } from "react";
import {
  Alert,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import KrgTextInput from "@kargo/shared-components.krg-text-input";
import KrgCheckbox from "@kargo/shared-components.krg-checkbox";
import { BUTTON_SIZE_ENUM, BUTTON_VARIANT_ENUM} from "@kargo/shared-components.krg-menu";
import { usePublishers } from "../../../hooks/usePublishers";
import {
  CreatedPublisherResponseData,
  CreatePublisherInput,
} from "../../../api/types";
import { useSuperAdminContext } from "../../../routes/super_admin";
import { setPublisherGroup } from "../../../helpers/publisherUsername";
import { usePublisherContext } from "../../../hooks/usePublisherProvider";
import LoadingCircle from "../../../components/LoadingCircle";

import "./styles.scss";
import * as styles from './style';
import TrackedButton from "../../../components/TrackedButton/TrackedKrgButton";

export default function AdminPublisherOnBoardPage() {
  const [inputs, setInput] = useState<CreatePublisherInput>({
    name: '',
    slug: '',
    domains: [],
    create_user: false,
    username: '',
  });
  const [errors, setError] = useState<{ key?: string; message: string }[]>([]);
  const [publisher, setPublisher] =
    useState<CreatedPublisherResponseData | null>(null);
  const { createNewPublisherMutation, query } = usePublishers();
  const {
    switchToPublisherGroup,
    switchToPublisher,
    switchToPublisherUsername,
  } = usePublisherContext(true);
  const { addPathToBreadCrumb } = useSuperAdminContext();
  const { mutate, isLoading, data, error } = createNewPublisherMutation;

  const resetErrors = (keys: string[] | undefined) => {
    setError(
      typeof keys === "undefined"
        ? []
        : errors.filter((err) => !keys.includes(err?.key)),
    );
  };

  useEffect(() => {
    addPathToBreadCrumb([
      {
        path: "/admin/publisher/onboard",
        pathName: "Publishers Onboard",
      },
    ]);
  }, []);

  useEffect(() => {
    resetErrors(["api"]);
    if (data) {
      if (data?.publisher) {
        setPublisher(data.publisher);
      } else if (error || data.message) {
        setError([
          ...errors,
          {
            key: "api",
            message: data?.message || JSON.stringify(error),
          },
        ]);
      } else {
        setError([
          ...errors,
          {
            key: "api",
            message: `Unexpected error occurred during publisher creation`,
          },
        ]);
      }
    }
  }, [data, error]);

  const handleSubmit = (ev: SubmitEvent) => {
    ev.preventDefault();
    resetErrors(["api"]);
    if (errors.length <= 0) mutate(inputs);
  };

  const handleOnChange = (id: string, text: string, required?: boolean) => {
    let slugOrName = text;
    if (required && text.length === 0) {
      setError([
        ...errors,
        {
          key: id,
          message: `Publisher ${id} is required`,
        },
      ]);
      return;
    }
    if (id === 'slug') {
      slugOrName = text.trim().split(' ').join("-").toLocaleLowerCase();
    }
    setInput({
      ...inputs,
      [id]: slugOrName.replace(/^(http(s)?:\/\/)?(www\.)?/, ''),
    });
  };

  const handleSwitchUser = (pl: CreatedPublisherResponseData) => {
    query.refetch().then(() => {
      switchToPublisher(pl.slug);
      switchToPublisherUsername(pl.name);
      switchToPublisherGroup(pl.group);
      setPublisherGroup(pl.group);
    });
  };

  const showNewCreatedPublisher = (pl: CreatedPublisherResponseData) => (
    <div style={{ paddingTop: "20px" }}>
      <Typography variant="h3" marginBottom="10px">
        Publisher Details:
      </Typography>
      <List sx={styles.listContainer}>
        <ListItem>
          <ListItemText primary={`Name: ${pl.name}`} />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText primary={`Slug: ${pl.slug}`} />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText primary={`Group: ${pl.group}`} />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText primary={`Created At: ${pl.created_at}`} />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText primary={`Created By: ${pl.created_by}`} />
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <TrackedButton
            id="switch-publisher"
            text={`Switch to "${publisher.name}"`}
            onClick={() => handleSwitchUser(publisher)}
            size={BUTTON_SIZE_ENUM.large}
            variant="contained"
            isFullWidth
          />
        </ListItem>
      </List>
    </div>
  );

  const createPublisherForm = () => (
    <Box sx={styles.formWrapper}>
      <Box sx={styles.textWrapper}>
        <Typography marginBottom={2} variant="h4">Create a new Publisher</Typography>
        <Typography variant="subtitle1">
          {`The Publisher's name is only necessary to create a publisher.
            But we can also can set specific slug
            (if not, one will be generated for the publisher based on the name). 
            Publisher’s group will be equal to its slug.`}
        </Typography>
      </Box>
      <Box sx={styles.inputWrapper}>
        <KrgCheckbox
          id="create_user"
          label="create user"
          inputType="text"
          isChecked={inputs.create_user}
          onToggle={(check) => setInput({ ...inputs, create_user: check })}
          theme={KrgTextInput.THEME_ENUM.v2}
        />
      </Box>
      <Box sx={styles.inputWrapper}>
        <KrgTextInput
          id="name"
          label="Publisher Name"
          inputType="text"
          hasAutoFocus
          required
          onDebounceEnd={(text) => handleOnChange("name", text, true)}
          hasErrorIcon={errors.filter((err) => err.key === "name").length > 0}
          errorMessage={
            errors?.length > 0
              ? errors.filter((err) => err.key === "name")[0]?.message || ``
              : ``
          }
          theme={KrgTextInput.THEME_ENUM.v2}
        />
        {inputs.name && (
          <KrgTextInput
            isEnabled={false}
            text={`Will submit: "${inputs.name}"`}
            style={{ width: `50%` }}
          />
        )}
      </Box>
      <Box sx={styles.inputWrapper}>
        <KrgTextInput
          id="slug"
          label="Slug Name (Optional)"
          inputType="text"
          hasAutoFocus
          onDebounceEnd={(text) => handleOnChange("slug", text, false)}
          hasErrorIcon={errors.filter((err) => err.key === "slug").length > 0}
          helperText={inputs.slug?.length > 0 && `Slug will be: ${(inputs.slug)}`}
          theme={KrgTextInput.THEME_ENUM.v2}
        />
        {inputs.slug && (
          <KrgTextInput
            isEnabled={false}
            text={`Will submit: "${inputs.slug}"`}
            style={{ width: `50%` }}
          />
        )}
      </Box>
      {
        inputs.create_user && (
          <Box sx={styles.inputWrapper}>
            <KrgTextInput
              id="username"
              label="Username"
              inputType="text"
              hasAutoFocus
              required={inputs.create_user}
              onDebounceEnd={(text) => handleOnChange("username", text, inputs.create_user)}
              hasErrorIcon={errors.filter((err) => err.key === "username").length > 0}
              errorMessage={
                errors?.length > 0
                  ? errors.filter((err) => err.key === "username")[0]?.message || ``
                  : ``
              }
              theme={KrgTextInput.THEME_ENUM.v2}
            />
          </Box>
        )
      }
      <Box sx={styles.inputWrapper}>
        <TrackedButton
          id="onboard-publisher"
          text="Onboard Publisher"
          isEnabled={errors.length <= 0 && inputs?.name?.length > 0}
          onClick={handleSubmit}
          size={BUTTON_SIZE_ENUM.medium}
          variant={BUTTON_VARIANT_ENUM.contained}
        />
      </Box>
    </Box>
  );
  if (isLoading) {
    return (
      <div className="center-div">
        <LoadingCircle />
      </div>
    );
  }

  return (
    <Box padding="10px 20px" display="flex" flexDirection="column">
      <Box>
        {errors.length > 0 && (
          <div>
            {errors.map(
              (err, i) => (
                err.key === `api` && (
                  <Alert key={`local-err-${err.key || i}`} severity="error">
                    {`Error Details: ${err.message}`}
                  </Alert>
                )
              ),
            )}
          </div>
        )}
      </Box>
      <Box>
        {publisher && (
          <Alert severity="success">
            {`Publisher: "${publisher.name}" was successfully created!`}
          </Alert>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between">
        {publisher ? showNewCreatedPublisher(publisher) : createPublisherForm()}
      </Box>
    </Box>
  );
}
