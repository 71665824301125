import React from "react";
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Circle } from "@mui/icons-material";
import { useHistory, useLocation } from "react-router-dom";
import useSession from "../../hooks/useSession";
import "./styles.scss";
import { ArrowDown, ArrowUp } from "../Icons";

function SidebarDivider() {
  return (
    <Divider variant="middle" sx={{ borderColor: "#C8D0D6", width: "233px" }} />
  );
}

function LeftListItemIcon({
  color,
}: {
  color?: "primary" | "secondary" | undefined;
}) {
  return (
    <ListItemIcon sx={{ minWidth: "30px" }}>
      <Circle sx={{ fontSize: "15px" }} color={color} />
    </ListItemIcon>
  );
}

function RightListItemIcon({ children }: React.PropsWithChildren) {
  return <ListItemIcon sx={{ minWidth: 0 }}>{children}</ListItemIcon>;
}

const baseListItemButtonSx = { px: "24px", height: "46px" };

type SidebarListItemButtonProps = {
  onClick: () => void;
  selected: boolean;
  text: string;
};

function SidebarListItemButton({
  onClick,
  selected,
  text,
}: SidebarListItemButtonProps) {
  return (
    <ListItemButton
      sx={{ ...baseListItemButtonSx }}
      onClick={onClick}
      selected={selected}
    >
      <LeftListItemIcon color={selected ? "primary" : undefined} />
      <ListItemText
        primary={text}
        primaryTypographyProps={
          selected ? { sx: { fontWeight: 600 } } : undefined
        }
      />
    </ListItemButton>
  );
}

type SideBarProps = {
  isPublisherAdmin: boolean;
};

export function Sidebar({ isPublisherAdmin }: SideBarProps) {
  const location = useLocation();
  const [open, setOpen] = React.useState(
    location.pathname.startsWith("/admin"),
  );
  const [bulkEdit, setBulkEditOpen] = React.useState(
    location.pathname.includes("/bulk"),
  );
  const history = useHistory();
  const session = useSession();
  const userGroups =
    session && session.getAccessToken().payload["cognito:groups"];
  const isSuperAdmin = userGroups && userGroups.includes("admin");
  const userIsAdmin = isSuperAdmin || isPublisherAdmin;

  return (
    <List sx={{ paddingTop: "20px" }}>
      <SidebarDivider />
      <SidebarListItemButton
        onClick={() => history.push("/publisher-hub")}
        selected={location.pathname === "/publisher-hub"}
        text="Publisher Hub"
      />
      <SidebarDivider />
      <SidebarListItemButton
        onClick={() => history.push("/playlists")}
        selected={location.pathname === "/playlists"}
        text="Playlists"
      />
      <SidebarDivider />
      <SidebarListItemButton
        onClick={() => history.push("/dashboards")}
        selected={location.pathname === "/dashboards"}
        text="Dashboards"
      />
      <SidebarDivider />
      <SidebarListItemButton
        onClick={() => history.push("/reporting")}
        selected={location.pathname === "/reporting"}
        text="Reports"
      />
      <SidebarDivider />
      {userIsAdmin && (
        <>
          <ListItemButton
            sx={baseListItemButtonSx}
            onClick={() => setOpen(!open)}
          >
            <LeftListItemIcon
              color={
                location.pathname.startsWith("/admin") ? "primary" : undefined
              }
            />
            <ListItemText
              primary="Admin"
              primaryTypographyProps={
                location.pathname.startsWith("/admin")
                  ? { sx: { fontWeight: 600 } }
                  : undefined
              }
            />
            <RightListItemIcon>
              {open ? (
                <ArrowUp alt="expand less" style={{ width: "9px" }} />
              ) : (
                <ArrowDown alt="expand more" style={{ width: "9px" }} />
              )}
            </RightListItemIcon>
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <SidebarListItemButton
                onClick={async () => history.push("/admin/users")}
                selected={location.pathname === "/admin/users"}
                text="User management"
              />
              {isSuperAdmin ? (
                <SidebarListItemButton
                  onClick={async () => history.push("/admin/domain")}
                  selected={location.pathname.includes("/admin/domain")}
                  text="Domain managment"
                />
              ) : null}
              {isSuperAdmin && (
                <SidebarListItemButton
                  onClick={async () => history.push("/admin/publisher/onboard")}
                  selected={location.pathname.includes(
                    "/admin/publisher/onboard",
                  )}
                  text="Onboard Publishers"
                />
              )}

              <ListItemButton
                sx={{ ...baseListItemButtonSx }}
                onClick={() => setBulkEditOpen(!bulkEdit)}
              >
                <LeftListItemIcon
                  color={
                    location.pathname.includes("/bulk") ? "primary" : undefined
                  }
                />
                <ListItemText
                  primary="Bulk Edit"
                  primaryTypographyProps={
                    location.pathname.includes("/bulk")
                      ? { sx: { fontWeight: 600 } }
                      : undefined
                  }
                />
                <RightListItemIcon>
                  {bulkEdit ? (
                    <ArrowUp alt="expand less" style={{ width: "9px" }} />
                  ) : (
                    <ArrowDown alt="expand more" style={{ width: "9px" }} />
                  )}
                </RightListItemIcon>
              </ListItemButton>
              <Collapse in={bulkEdit} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <SidebarListItemButton
                    onClick={async () => history.push("/admin/bulk-update")}
                    selected={location.pathname.includes("/admin/bulk-update")}
                    text="Update"
                  />
                  <SidebarListItemButton
                    onClick={async () => history.push("/admin/bulk-delete")}
                    selected={location.pathname.includes("/admin/bulk-delete")}
                    text="Delete"
                  />
                </List>
              </Collapse>
            </List>
          </Collapse>
        </>
      )}
    </List>
  );
}
